// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-axie-farm-js": () => import("./../../../src/pages/axie-farm.js" /* webpackChunkName: "component---src-pages-axie-farm-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-food-menu-js": () => import("./../../../src/pages/food-menu.js" /* webpackChunkName: "component---src-pages-food-menu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nft-collection-js": () => import("./../../../src/pages/nft-collection.js" /* webpackChunkName: "component---src-pages-nft-collection-js" */),
  "component---src-pages-oscar-js": () => import("./../../../src/pages/oscar.js" /* webpackChunkName: "component---src-pages-oscar-js" */),
  "component---src-pages-secret-under-construction-jsx": () => import("./../../../src/pages/secret-under-construction.jsx" /* webpackChunkName: "component---src-pages-secret-under-construction-jsx" */),
  "component---src-pages-treasury-js": () => import("./../../../src/pages/treasury.js" /* webpackChunkName: "component---src-pages-treasury-js" */),
  "component---src-templates-axie-account-template-jsx": () => import("./../../../src/templates/AxieAccountTemplate.jsx" /* webpackChunkName: "component---src-templates-axie-account-template-jsx" */),
  "component---src-templates-food-menu-template-jsx": () => import("./../../../src/templates/FoodMenuTemplate.jsx" /* webpackChunkName: "component---src-templates-food-menu-template-jsx" */)
}

